export const breakpoints = {
  /** 376 (mobile is from 376px to 767px) */
  mobile: 376,
  /** 768 (tablet is from 768px to 1023px) */
  tablet: 768,
  /** 1024 (smallDesktop is from 1024px to 1279px) */
  smallDesktop: 1024,
  /** 1280 (desktop is from 1280px to 1439px) */
  desktop: 1280,
  /** 1440 (largeDesktop is from 1440px to 1919px) */
  largeDesktop: 1440,
  /** 1920 (monitor is from 1920px to infinity) */
  monitor: 1920,
};
