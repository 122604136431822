import { useMediaQuery } from "react-responsive";
import { breakpoints } from "./breakpoints";

/**
 * @returns {object} - an object with the following properties:
 * - isMobile: boolean, true when the screen is 767px or narrower
 * - isTablet: boolean, true when the screen is 768px or wider
 * - isSmallDesktop: boolean, true when the screen is 1024px or wider
 * - isDesktop: boolean, true when the screen is 1280px or wider
 * - isLargeDesktop: boolean, true when the screen is 1440px or wider
 * - isMonitor: boolean, true when the screen is 1920px or wider
 */
export function useBreakpoints() {
  /** 0 - 767px is mobile */
  const isMobile = useMediaQuery({ maxWidth: breakpoints.tablet - 1 });

  /** 768px and up */
  const isTablet = useMediaQuery({ minWidth: breakpoints.tablet });

  /** 1024px and up */
  const isSmallDesktop = useMediaQuery({ minWidth: breakpoints.smallDesktop });

  /** 1280px and up */
  const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop });

  /** 1440px and up */
  const isLargeDesktop = useMediaQuery({ minWidth: breakpoints.largeDesktop });

  /** 1920px and up */
  const isMonitor = useMediaQuery({ minWidth: breakpoints.monitor });

  return {
    isMobile,
    isTablet,
    isDesktop,
    isSmallDesktop,
    isLargeDesktop,
    isMonitor,
  };
}
